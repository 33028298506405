<template>
  <div class="control-configure">
    <configure-decorate
      v-if="control.curWidget"
      :title="control.curWidget.name"
      :key="control.curWidget.id"
    >
      <schema-template
        :schema="curSchema"
        v-model="control.curWidget"
        :key="curSchema.id"
      ></schema-template>
    </configure-decorate>
    <configure-decorate v-else title="全局配置">
      <page-config></page-config>
    </configure-decorate>
  </div>
</template>

<script>
import PageConfig from "@/components/PageConfig";
export default {
  name: "ControlConfigure",
  inject: ["control"],
  components: { PageConfig },
  data() {
    return {};
  },
  computed: {
    curSchema() {
      console.log("------------------------");
      console.log("curSchema222222", this.control.curWidget);
      console.log("curValue 3333333", this.$fields[this.control.curWidget.component]);
      console.log("------------------------");
      return this.$fields[this.control.curWidget.component];
    },
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.control-configure {
  min-width: 485px;
  position: relative;
  z-index: 30;
}
</style>
