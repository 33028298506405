<template>
  <div class="RealTimeView">
    <el-dialog
      class="dialogView"
      v-if="mShow"
      :append-to-body="true"
      :visible.sync="mShow"
      top="8vh"
    >
      <phone-ctn :head="false">
        <iframe
          id="cover"
          v-if="mShow"
          ref="iframeView"
          class="screen"
          :scrolling="false"
          :src="iframeUrl"
          @load="load"
        ></iframe>
      </phone-ctn>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RealTimeView",

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      mShow: false,
    };
  },

  computed: {
    ...mapGetters(["project"]),

    iframeUrl() {
      const isEnv = process.env.VUE_APP_NODE_ENV === "staging";
      if (isEnv) {
        return `http://${window.location.hostname}:3020/h5/revealPage/timeView`;
      } else {
        return `${window.location.origin}/h5/revealPage/timeView`;
      }
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(newValue) {
        this.mShow = newValue;
      },
    },
    mShow: {
      immediate: true,
      handler(newValue) {
        this.$emit("update:show", newValue);
      },
    },
  },

  methods: {
    load() {
      this.$nextTick(() => {
        console.log(this.project);
        this.$refs["iframeView"] &&
          this.$refs["iframeView"].contentWindow.postMessage(
            { params: this.project, even: "timeView" },
            "*"
          );
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  min-width: 415px;
}
/deep/.el-dialog__body {
  min-width: 415px;
  margin: 0;
}
.screen {
  width: 375px /*no*/;
  height: 650px /*no*/;
  border: 0;
  margin: auto;
  overflow: auto;
  // 隐藏滚动条
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}

/deep/.PhoneCtn {
  width: 414px;
  margin: 0;

  .preview-height-tag {
    display: none;
  }

  .preview-body {
    height: 667px;
    // 隐藏滚动条
  }
}
</style>
