<template>
  <div class="control">
    <!-- 模块列表 -->

    <ControlModels></ControlModels>
    <!-- editor 编辑面板 -->
    <ControlPanel></ControlPanel>
    <!-- 配置部分 -->
    <ControlConfigure></ControlConfigure>
  </div>
</template>

<script>
import ControlModels from "./components/ControlModels";
import ControlPanel from "./components/ControlPanel";
import ControlConfigure from "./components/ControlConfigure";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "control",
  data() {
    return {
      dragstatus: false, //拖拽中
      curWidget: null, //当前拖拽的组件
      dragWidget: null, //
    };
  },
  provide() {
    return {
      control: this,
    };
  },
  mounted() {},
  components: {
    ControlModels,
    ControlPanel,
    ControlConfigure,
  },
  computed: {
    ...mapGetters(["project"]),
  },
  methods: {
    ...mapMutations(["setProject", "setDefaultProject"]),
  },
};
</script>

<style lang="less" scoped>
.control {
  display: flex;
  width: 100%;
  height: calc(100vh - 56px);
  // background: #dedede;
  position: relative;
}
</style>
