<template>
  <div class="control-models">
    <div class="wrap" v-for="(itemType, indexType) in $modelType" :key="indexType">
      <div class="wrap-label">
        <span>{{ itemType }}</span>
      </div>
      <template v-if="activityInfo">
        <ul class="flex flex-wrap pt10 pb10">
          <li
            v-for="(item, index) in $initializing"
            :key="index"
            class="item"
            :data-component="item.component"
            draggable
            @dragstart="handleDragStart"
            @dragend="dragEnd"
            v-show="isShowModel(item, itemType)"
          >
            <i class="iconfont icon" :class="item.icon"></i>
            <span>{{ item.name }}</span>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
import { getActivityDetail } from "@/api/activity";

export default {
  inject: ["control"],
  data() {
    return {
      activityInfo: null,
    };
  },
  watch: {
    $initializing(newVal) {
      console.log(newVal);
    },
  },
  mounted() {
    console.log("this.$initializing=", this.$initializing);
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const resp = await getActivityDetail(this.$route.params.uuid);
      this.activityInfo = resp.data;
    },
    isShowModel(item, itemType) {
      if (item.modelType == itemType) {
        if (item.component == "LuckDraw") {
          return this.activityInfo.lucky_draw;
        }
        if (item.component == "Advert") {
          return false;
        }
        return true;
      }
      return false;
    },
    // 拖拽开始
    handleDragStart(e) {
      console.log("拖拽开始！！！！", e);
      this.control.dragWidget = this.$getNewComponent(e.target.dataset.component);
      this.control.dragstatus = true;
    },
    dragEnd(e) {
      console.log("拖拽结束！！！！", e);
      this.control.dragstatus = false;
    },
  },
  components: {},
};
</script>

<style lang="less" scoped>
.control-models {
  padding: 20px 20px 10px 20px;
  min-width: 485px;
  position: relative;
  z-index: 30;
  background: #fff;
  height: calc(100% - 40px);
  border-bottom: 1px solid #ebedf0;
  .menu {
    height: 100%;
    // border-right:1px solid #000;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1);
    p {
      height: 40px;
      line-height: 40px;
      position: relative;
      cursor: pointer;
      &.active {
        color: #2c5acc;
        &::after {
          content: "";
          background: #2c5acc;
          width: 2px;
          height: 26px;
          border-radius: 20%;
          position: absolute;
          top: 7px;
          right: 0;
        }
      }
    }
  }
  .wrap-label {
    padding: 10px 12px;
    background: #e8f0fb40;
    font-size: 14px;
    color: #323233;

    span {
      &::before {
        color: @theme-color;
        content: ".";
        width: 3px;
        height: 10px;
        margin-right: 8px;
        background: @theme-color;
      }
    }
  }
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 72px;
    margin-bottom: 8px;
    padding: 10px 0;
    font-size: 12px;
    color: #666;

    i {
      font-size: 29px;
      margin-top: 5px;
      margin-bottom: 10px;
    }

    &:hover {
      color: #fff;
      background: @theme-color;
    }
  }
}
</style>
