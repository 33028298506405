<template>
  <div class="control-panel" @click.stop="control.curWidget = null">
    <div
      class="phone-page"
      :style="{
        height: iframeHeight + 'px',
      }"
    >
      <!-- 组件 放置区域 -->
      <iframe
        ref="iframe"
        class="page-iframe"
        frameborder="no"
        :style="{
          height: iframeHeight + 'px',
          pointerEvents: control.dragstatus ? 'none' : 'auto',
        }"
        :src="iframeUrl"
        @load="load"
      ></iframe>

      <!-- iframe 上的交互层 -->
      <div
        class="phone-page-player"
        @dragover="layerMove"
        @drop="drop"
        @dragleave="dragleave"
        :style="{
          height: iframeHeight + 'px',
          zIndex: control.dragstatus ? '20' : '1',
        }"
      >
        <ControlModelShape
          v-for="item in widgetInfoList"
          :key="item.id"
          :data="item"
          @changeCurrWidget="changeCurrWidget"
        >
          <div
            ref="layerWidget"
            class="page-layer-widget"
            type="widget"
            :id="item.id"
            :style="{ height: item.height + 'px' }"
          ></div>
        </ControlModelShape>
      </div>
    </div>
    <div class="pageSetting" @click.stop="control.curWidget = null">
      <i class="el-icon-setting"></i>
    </div>
  </div>
</template>

<script>
import ControlModelShape from "./ControlModelShape.vue";
import { getPageInfo } from "@/api/common";
import { settingProject } from "@/utils/project";
export default {
  components: { ControlModelShape },
  inject: ["control"],
  data() {
    return {
      widgetInfoList: [],
      iframeHeight: 650,
    };
  },
  computed: {
    iframeUrl() {
      console.log("process.env.VUE_APP_NODE_ENV: ", process.env.VUE_APP_NODE_ENV);
      const isEnv = process.env.VUE_APP_NODE_ENV == "staging";
      // 本地 http  测试环境 https
      if (isEnv) {
        console.log("window.location.origin==>3020/h5: ");
        return `http://${window.location.hostname}:3020/h5/buildPage`;
      } else {
        console.log("window.location.origin==>/h5: ");
        return `${window.location.origin}/h5/buildPage`;
      }
    },
  },
  // 监听物料列表发生变化，通知iframe同步更新
  watch: {
    "control.project.componentList": {
      handler() {
        console.log("this.control.project:999 ", this.control.project);
        settingProject(this.control.project);
        this.messageList();
      },
      deep: true,
    },
    "control.project.config": {
      handler(val) {
        settingProject(this.control.project);
        this.control.h5Iframe.contentWindow.postMessage(
          {
            even: "changePageConfig",
            params: { config: val },
          },
          "*"
        );
      },
      deep: true,
    },
  },
  mounted() {
    this.control.h5Iframe = this.$refs.iframe;
    this.getMessage();
    this.init();
  },
  methods: {
    load() {
      this.$nextTick(() => {
        console.log(this.project);
        // 发送信息，同步初始化iframe
        this.messageInit();
        this.messageList();
      });
    },
    init() {
      const self = this;
      const uuid = self.$route.params.uuid;
      self.control.setDefaultProject(); // 重制 初始化项目

      getPageInfo(uuid).then((res) => {
        if (res.data) {
          let { content } = res.data;
          console.log('==============eval("(" + content + ")")=============');
          console.log(res);
          let project = eval("(" + content + ")");
          console.log(project);
          console.log("====================================");
          self.control.setProject(project); //赋值新项目数据
        }
      });
    },

    // 接收iframe信息
    getMessage() {
      let self = this;
      window.addEventListener("message", function (e) {
        let { type, params } = e.data;
        // console.log("type, params", type, params);
        switch (type) {
          case "setList":
            self.setList(params);
            break;
          case "setHeight":
            self.setHeight(params);
            break;
          case "setCurrWidget":
            self.setCurrWidget(params);
            break;
        }
      });
    },

    // 设置页面高度
    setHeight(params) {
      this.widgetInfoList = params;
      this.iframeHeight = this.widgetInfoList.reduce((a, b) => a + b.height, 0);
      // console.log(`当前高度：${this.iframeHeight}`);
    },

    // iframe内物料列表发生变化，同步更新
    setList(params) {
      console.log(params);
      let { list } = params;
      this.control.project.componentList = list;
    },

    // 设置选中物料
    setCurrWidget(params) {
      let { id } = params;
      this.control.curWidget = this.control.project.componentList.find((item) => id == item.id);
    },

    // 修改选中物料，并通知iframe，同步更新
    changeCurrWidget(widget) {
      console.log("修改选中物料，并通知iframe，同步更新");
      console.log(widget);
      this.setCurrWidget(widget);
      this.$refs.iframe.contentWindow.postMessage(
        {
          even: "changeCurrWidget",
          params: { id: widget.id },
        },
        "*"
      );
    },

    // 调用物料拖拽移动(节流)
    layerMove(e, index) {
      e.preventDefault();
      this.throttle(this.layerMoveFun, 1)(e, index);
    },

    // 物料拖拽移动,控制waiting移动
    layerMoveFun(e) {
      e.preventDefault();
      e.stopPropagation();
      console.log(" 物料拖拽移动,控制waiting移动", this.control.dragWidget);
      if (!this.control.dragstatus) return;

      let type = e.target.getAttribute("type");
      let params = {
        type: "page",
      };

      if (type == "widget") {
        let [y, h] = [e.offsetY, e.target.offsetHeight];
        params = {
          id: e.target.getAttribute("id"),
          type: "widget",
          direction: y < h / 2,
        };
      }

      this.$refs.iframe.contentWindow.postMessage(
        {
          even: "move",
          params,
        },
        "*"
      );
    },
    // 拖动物料在有效区域释放时,将物料展示在构建区域
    drop(e) {
      e.preventDefault();
      this.control.h5Iframe.contentWindow.postMessage(
        {
          even: "drop",
          params: this.control.dragWidget,
        },
        "*"
      );
    },
    // 当移动中的组件脱离构建区域时触发,移除放置区域占位
    dragleave(e) {
      e.preventDefault();
      this.control.h5Iframe.contentWindow.postMessage(
        {
          even: "removeWaiting",
        },
        "*"
      );
    },

    // 发送信息，当前整个配置数据
    messageInit() {
      console.log("messageInit", this.control.project);
      this.$refs.iframe.contentWindow.postMessage(
        {
          even: "init",
          params: { project: this.control.project },
        },
        "*"
      );
    },

    // 发送信息，同步iframe种物料数组
    messageList() {
      console.log("messageList", this.control.project.componentList);
      this.$refs.iframe.contentWindow.postMessage(
        {
          even: "list",
          params: this.control.project.componentList,
        },
        "*"
      );
    },

    // 节流
    throttle(func, wait) {
      let timeout;
      return function () {
        let context = this;
        let args = arguments;
        if (!timeout) {
          timeout = setTimeout(() => {
            timeout = null;
            func.apply(context, args);
          }, wait);
        }
      };
    },
  },
};
</script>

<style lang="less" scoped>
.control-panel {
  min-height: 650px;
  flex: 1;
  background: #cfcfcf;
  overflow: auto;
  margin-bottom: 40px;
  position: relative;
  border-bottom: 1px solid #ebedf0;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  .phone-page {
    background: #ffffff;
    width: 375px;
    min-height: 650px;
    margin: 70px auto;
    position: relative;
    box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
    .phone-page-player {
      width: 100%;
      min-height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 20;
    }
    .page-iframe {
      position: relative;
      width: 100%;
      // min-height: 650px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
    }
  }
  .pageSetting {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    font-size: 18px;
    width: 26px;
    height: 26px;
    border: solid 1px #ededed;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: @theme-color;
      border: solid 1px @theme-color;
    }
  }
}
</style>
