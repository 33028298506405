<template>
  <div class="shape">
    <!-- 组件工具栏 -->
    <div
      v-if="data.component != 'waiting' && data.height > 0"
      class="shape-tab"
      @click="setcurComponent(data)"
      :style="[getCusStyle(data)]"
    >
      <!-- 删除 -->
      <template v-if="control.curWidget && control.curWidget.id == data.id">
        <i class="icon el-icon-delete tab-icon f16" @click.stop="delComponent(data.id)"></i>
      </template>

      <!-- 组件名 -->
      <span v-else> {{ data.routine.modelName ? data.routine.modelName : data.name }}</span>
    </div>

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "shape",

  inject: ["control"],

  props: {
    data: {
      type: Object,
    },
  },

  methods: {
    // 修改选中物料
    setcurComponent() {
      this.$emit("changeCurrWidget", this.data);
    },

    // 删除物料
    delComponent(id) {
      let index = this.control.project.componentList.findIndex((item) => item.id == id);
      this.control.project.componentList.splice(index, 1);
      this.control.curWidget = undefined;
    },

    getCusStyle() {
      return {
        border: this.data.routine.error ? `1px solid red` : "none",
      };
    },
  },
};
</script>

<style lang="less" scoped>
.shape {
  position: relative;

  .shape-tab {
    position: absolute;
    right: -86px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 24px;
    font-size: 12px;
    color: #333;
    background: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &::after {
      content: "";
      position: absolute;
      right: 100%;
      top: 7px;
      width: 0;
      height: 0;
      border-width: 5px;
      border-style: solid;
      border-color: transparent;
      margin-bottom: -1px;
      border-right-width: 5px;
      border-right-color: currentColor;
      color: #fff;
    }
  }

  .event-none {
    pointer-events: none;
  }

  .tab-icon {
    width: 100%;
    text-align: center;
    color: #969799;
    cursor: pointer;

    &:hover {
      color: #87888a;
    }
  }
}
</style>
