<template>
  <div class="canvas">
    <TopBar></TopBar>
    <Control></Control>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar/index.vue";
import Control from "@/components/Control/index.vue";
export default {
  data() {
    return {};
  },
  methods: {},
  components: {
    TopBar,
    Control,
  },
};
</script>

<style scoped></style>
